<template>
  <div class="service-container">
    <div class="g-section">
      <div class="g-wrap service-list"></div>
    </div>
    <div class="g-section">
      <div class="g-wrap info-list" v-loading="loading">
        <div
          v-for="(item, i) in list"
          class="info-item"
          :key="i"
          @click="$router.push('information/detail?id=' + item.Id)"
        >
          <div class="item-left">
            <img
              class="item-img"
              src="@/assets/images/nodata.png"
              v-real-img="item.Illustration"
              alt=""
            />
            <!-- <span class="item-tag">{{ item.ContentTypeText }}</span> -->
          </div>
          <div class="item-message">
            <div>
              <span class="message-title">{{ item.Headline }}</span>
              <p class="message-summary text-line-2">
                {{ item.Summary }}
              </p>
            </div>
            <div class="message-time">
              <span class=""
                >来源：{{ item.Source || "广东轻工职业技术学院" }}</span
              >
              <span>{{ item.ReleaseTime }}</span>
            </div>
          </div>
        </div>
        <div class="loading" v-if="totalCount > 10">
          <Pagination
            :total="totalCount"
            :layout="'prev, pager, next'"
            @pagination="SelectPagination"
          />
        </div>
      </div>
    </div>
    <AnswerTop />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getPageList } from "@/api/news";
import AnswerTop from "@/components/AnswerTop";
export default {
  components: {
    Pagination,
    AnswerTop
  },
  data() {
    return {
      loading: false,
      queryData: {
        KeyWord: "",
        ContentType: null,
        PageSize: 10,
        PageIndex: 1
      },
      list: [],
      totalCount: 0
    };
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    this.selectload();
  },
  methods: {
    selectload() {
      this.loading = true;
      getPageList(this.queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = Data;
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    SelectPagination(val) {
      this.queryData.PageSize = val.limit;
      this.queryData.PageIndex = val.page;
      this.selectload();
    }
  }
};
</script>

<style lang="scss" scoped>
.service-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  flex: 1;
}

.g-section {
  background-color: #ffffff;
}
.service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.service-item {
  display: flex;
  flex-direction: column;
  width: 24%;
  height: 150px;
  // justify-content: space-between;
  justify-content: space-around;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-image: url("../../assets/images/bg_information.jpg");
  border-radius: 6px;
  background-size: 100% 100%;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px 0px #8fabfa;
    .service-title {
      color: #598DF3;
    }
  }
  .service-title {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }
  .service-summary {
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
.info-list {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 20px;
  min-height: 30vh;
  .info-item {
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    padding: 20px;
    height: 200px;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    &:hover {
      box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.16),
        0px -1px 0px 0px #eeeeee;
      border-radius: 8px;
    }
    .item-message {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 20px;
      justify-content: space-between;
      .message-title {
        text-align: left;
        font-size: 20px;
        color: #333333;
        font-weight: 600;
        padding-top: 0px;
      }
      .message-summary {
        text-align: left;
        font-size: 14px;
        color: #666666;
      }
      .message-time {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999999;
      }
    }
    &:hover {
      .message-title {
        color: #598DF3;
      }
      .message-summary {
        color: #333333;
      }
    }
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 6px;
      overflow: hidden;
    }
    .item-img {
      height: 160px;
      width: 280px;
      object-fit: cover;
    }
    // .item-tag {
    //   color: #ffffff;
    //   font-size: 14px;
    //   background-image: url("https://zxj.ve-city.com/files/zxj/portal/bg_tag.png");
    //   position: relative;
    //   margin-top: -146px;
    //   margin-left: -6px;
    //   background-size: 100% 100%;
    //   height: 34px;
    //   width: 76px;
    //   text-align: center;
    //   box-sizing: border-box;
    //   padding-top: 4px;
    // }
  }
  .loading {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
</style>
